<template>
<div>
  <conversations-answered :conversationsData="stats" :options="chartOptions" />
  <!-- <conversations-charts :conversationsData="stats" :options="chartOptions" /> -->
  <scan-charts :scanData="stats" :options="chartOptions" />
  <messaging-charts :scanData="stats" :options="chartOptions" />
  <deliverability-charts :scanData="stats" :options="chartOptions" />


</div>
</template>

<script>
import api from "@/api/backend-api";
import ConversationsAnswered from "@/components/ConversationsAnswered";
import ScanCharts from "@/components/ScanCharts";
import MessagingCharts from "@/components/MessagingCharts";
import DeliverabilityCharts from "@/components/DeliverabilityCharts";

export default {
  components: {
    ConversationsAnswered,
    ScanCharts,
    MessagingCharts,
    DeliverabilityCharts
  },
  data() {
    return {
      stats: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              // OR //
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
      },
    };
  },

  async created() {
    const { data } = await api.getStats();
    this.stats = data;
  },
};
</script>

<style></style>
