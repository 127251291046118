<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    label: {
      type: String,
    },
    scanData: {
      type: Array,
    },
    options: {
      type: Object,
    },
    chartColors: {
      type: Object,
    },
  },
  mounted() {
    this.chardData();
  },
  methods: {
    chardData() {
      const dates = this.scanData.map((d) => d.date);
      const sent = {
        label: "Sent Out",
        data: this.scanData.map((d) => d.messagesSent),
        borderColor: "grey",
      };
      const delivered = {
        label: "Delivered",
        data: this.scanData.map((d) => d.messagesDelivered),
        borderColor: "blue",
      };
      const howmuch = {
        label: "How Much",
        data: this.scanData.map((d) => d.responsesHowMuch),
        borderColor: "blue",
      };
      const confirmed = {
        label: "Confirmed",
        data: this.scanData.map((d) => d.responsesEmails),
        borderColor: "red",
      };
      const nothanks = {
        label: "No Thanks",
        data: this.scanData.map((d) => d.responsesNoThanks),
        borderColor: "black",
      };
      const responsesTotal = {
        label: "Responses Total",
        data: this.scanData.map((d) => d.responsesReceivedTotal),
        borderColor: "silver",
      };
      this.renderChart(
        {
          labels: dates,
          datasets: [
            // sent,
            // delivered,
            howmuch,
            confirmed,
            nothanks,
            // responsesTotal,
            // {
            //   label: "Failed",
            //   data: this.conversationsData.map((d) => d.conversationsFailed),
            // },
          ],
        },
        this.options
      );
    },
  },
  watch: {
    scanData() {
      this.chardData();
    },
  },
};
</script>
