<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    label: {
      type: String,
    },
    conversationsData: {
      type: Array,
    },
    options: {
      type: Object,
    },
    chartColors: {
      type: Object,
    },
  },
  mounted() {
    this.chardData();
  },
  methods:{
    chardData(){
      const dates = this.conversationsData.map((d) => d.date);
    const totals = {
      label: "Total",
      data: this.conversationsData.map((d) => d.conversationsTotal),
      borderColor: "grey",
    };
    const ratio = {
      label: "Responsiveness Ratio",
      data: this.conversationsData.filter(c=> c.conversationsAnswered<c.conversationsNoAnswer)
        .map((d) => d.conversationsAnswered/d.conversationsNoAnswer*100),
      borderColor: "blue",
    };
    const answered = {
      label: "Answered",
      data: this.conversationsData.map((d) => d.conversationsAnswered),
      borderColor: "green",
    };
    const noAnswer = {
      label: "No Answer",
      data: this.conversationsData.map((d) => d.conversationsNoAnswer),
      borderColor: "red",
    };
    this.renderChart(
      {
        labels: dates,
        datasets: [
          // totals,
          // falls,
          // answered,
          // noAnswer,
          ratio,
          // {
          //   label: "Failed",
          //   data: this.conversationsData.map((d) => d.conversationsFailed),
          // },
        ],
      },
      this.options
    );
    }
  },
  watch:{
    conversationsData(){
      this.chardData();
    }
  }
};
</script>
