<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  props: {
    label: {
      type: String,
    },
    scanData: {
      type: Array,
    },
    options: {
      type: Object,
    },
    chartColors: {
      type: Object,
    },
  },
  mounted() {
    this.chardData();
  },
  methods:{
    chardData(){
      const dates = this.scanData.map((d) => d.date);
    const totals = {
      label: "Total",
      data: this.scanData.map((d) => d.leadsTotal),
      borderColor: "grey",
    };
    const fails = {
      label: "US",
      data: this.scanData.map((d) => d.leadsUS),
      borderColor: "blue",
    };
    this.renderChart(
      {
        labels: dates,
        datasets: [
          totals,
          fails,
          // {
          //   label: "Failed",
          //   data: this.conversationsData.map((d) => d.conversationsFailed),
          // },
        ],
      },
      this.options
    );
    }
  },
  watch:{
    scanData(){
      this.chardData();
    }
  }
};
</script>
